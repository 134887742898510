// Libraries
@import 'nouislider/dist/nouislider.css';

$red: #ef5350;
$green: #00cc7e;
$screeching-white: #eee;
$froth: #fafafa;
$sword-steel: #bdbdbd;

.noUi-connect { background: $green; } // scss-lint:disable SelectorFormat

.dropzone {
  align-items: center;
  background-color: $froth;
  border-color: $screeching-white;
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  color: $sword-steel;
  display: flex;
  flex: 1;
  flex-direction: column;
  outline: none;
  padding: 20px;
  transition: border .24s ease-in-out;

  &:focus { border-color: $green; }
  &.disabled { opacity: .6; }
}

.invalid-dropzone .dropzone { border-color: $red; }
